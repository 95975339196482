import { useForm, Controller } from 'react-hook-form';
//import emailjs from 'emailjs-com';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircle, faCheck, } from '@fortawesome/free-solid-svg-icons';
import { faEnvelope, faCartShopping, faPhone, faLocationDot, faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import { faFacebook, faInstagram, faYoutube } from '@fortawesome/free-brands-svg-icons';
import GoogleMapReact from 'google-map-react';
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
//import Mailjet from 'node-mailjet';
import axios from "axios";
import { ErrorMessage } from "@hookform/error-message"
import confetti from "canvas-confetti";
import { BotonEnviando } from './BotonEnviando';


export const Contacto = () => {
    var [mensajeEnviado, setMensajeEnviado] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const confites = () => {
        setTimeout(() => {
            confetti({
                spread: 2500,
                origin: { y: 0 },
                particleCount: 200,
                startVelocity: 22,
                angle: 120,
                //spread: 90,
                // origin: { x: 1 }
                /*
                origin: {
                x: Math.random(),
                // since they fall down, start a bit higher than random
                y: Math.random() - 0.2
                }
                angle: 120,
                */
            });
        }, 1000);
    }
    const getMensajeEnviado = (nuevo) => {
        //sucursales1 = nuevo;
        setMensajeEnviado(nuevo);
        scrollIdentificador('contacto');
    }

    const scrollIdentificador = (nombre) => {
        const element = document.getElementById(nombre);
        if (element) {
            // 👇 Will scroll smoothly to the top of the next section
            element.scrollIntoView({ behavior: 'smooth' });
        }
    }

    /*
    const {
        register,
        handleSubmit,
        reset,
        formState: { errors }
    } = useForm();*/

    const {
        register,
        handleSubmit,
        control,
        reset,
        formState: { errors },
    } = useForm();



    // Function that displays a success toast on bottom right of the page when form submission is successful
    const toastifySuccess = () => {
        toast('Form sent!', {
            position: 'bottom-right',
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: false,
            className: 'submit-feedback success',
            toastId: 'notifyToast'
        });
    };

    // Function called on submit that uses emailjs to send email of valid contact form
    const onSubmit = async (data) => {

        //        alert("enviando email2");


        console.log(data)


        await axios
            .post(process.env.REACT_APP_URL_API + "/contactar/", data)
            .then(({ data }) => {
                reset();
                getMensajeEnviado(true);
                setIsLoading(false);
                //alert("holis")
                confites();
                //console.log("++++++++++++")
                //console.log(data)
                //idReturn = data.id;
                //copiar de temp a prodcutos/id
                //borrar temp
                // todo ok toast susecc
            })//toast.success(data)
            .catch(({ data }) => toast.error(data));


        // const Mailjet = require('node-mailjet');



        /*
                // Destrcture data object
                const { nombre, apellido, email, mensaje } = data;
                try {
                    const templateParams = {
                        nombre,
                        apellido,
                        email,
                        mensaje
                    };
                    /*
                                await emailjs.send(
                                    process.env.REACT_APP_SERVICE_ID,
                                    process.env.REACT_APP_TEMPLATE_ID,
                                    templateParams,
                                    process.env.REACT_APP_USER_ID
                                );
                    








        
        reset();
        getMensajeEnviado(true);
        toastifySuccess();
    } catch (e) {
        alert("nop");
        console.log(e);
    }

        */
    };

    const AnyReactComponent = ({ text }) => <div className='mapa'><FontAwesomeIcon icon={faLocationDot} className='icono-mapa' /><span className='texto-mapa'>{text}</span></div>;

    const defaultProps = {
        center: {
            lat: -34.707830789876986,
            lng: -58.378881311820436
        },
        zoom: 12
    };

    return (
        <section className='bg-[#F1F5F9] dark:bg-[#070a1c] margen1' id='contacto'>

            <h2 className='titulo1 text-center pb-2 '>Contacto</h2>






            <div className='grid grid-cols-1 lg:grid-cols-2 gap-4'>

                <div className='flex justify-center pt-2 font-bold dark:font-[10] text-base leading-6'>
                    <div>
                        <div className='flex text-left pb-4'>
                            <div className='pr-1'>
                                <svg xmlns="http://www.w3.org/2000/svg" className="ico-seguinos whatsapp" viewBox="0 0 448 512"><path d="M380.9 97.1C339 55.1 283.2 32 223.9 32c-122.4 0-222 99.6-222 222 0 39.1 10.2 77.3 29.6 111L0 480l117.7-30.9c32.4 17.7 68.9 27 106.1 27h.1c122.3 0 224.1-99.6 224.1-222 0-59.3-25.2-115-67.1-157zm-157 341.6c-33.2 0-65.7-8.9-94-25.7l-6.7-4-69.8 18.3L72 359.2l-4.4-7c-18.5-29.4-28.2-63.3-28.2-98.2 0-101.7 82.8-184.5 184.6-184.5 49.3 0 95.6 19.2 130.4 54.1 34.8 34.9 56.2 81.2 56.1 130.5 0 101.8-84.9 184.6-186.6 184.6zm101.2-138.2c-5.5-2.8-32.8-16.2-37.9-18-5.1-1.9-8.8-2.8-12.5 2.8-3.7 5.6-14.3 18-17.6 21.8-3.2 3.7-6.5 4.2-12 1.4-32.6-16.3-54-29.1-75.5-66-5.7-9.8 5.7-9.1 16.3-30.3 1.8-3.7 .9-6.9-.5-9.7-1.4-2.8-12.5-30.1-17.1-41.2-4.5-10.8-9.1-9.3-12.5-9.5-3.2-.2-6.9-.2-10.6-.2-3.7 0-9.7 1.4-14.8 6.9-5.1 5.6-19.4 19-19.4 46.3 0 27.3 19.9 53.7 22.6 57.4 2.8 3.7 39.1 59.7 94.8 83.8 35.2 15.2 49 16.5 66.6 13.9 10.7-1.6 32.8-13.4 37.4-26.4 4.6-13 4.6-24.1 3.2-26.4-1.3-2.5-5-3.9-10.5-6.6z" /></svg>
                            </div>
                            <div className='leading-6 '>
                                <span class="font-bold">Whatsapp:</span>  <span class="">54 9 11 2587 0303</span>
                            </div>
                        </div>

                        <div className='flex text-left pb-4'>
                            <div className='pr-1'>
                                <svg xmlns="http://www.w3.org/2000/svg" className="ico-seguinos email" viewBox="0 0 512 512"><path d="M48 64C21.5 64 0 85.5 0 112c0 15.1 7.1 29.3 19.2 38.4L236.8 313.6c11.4 8.5 27 8.5 38.4 0L492.8 150.4c12.1-9.1 19.2-23.3 19.2-38.4c0-26.5-21.5-48-48-48H48zM0 176V384c0 35.3 28.7 64 64 64H448c35.3 0 64-28.7 64-64V176L294.4 339.2c-22.8 17.1-54 17.1-76.8 0L0 176z" /></svg>
                            </div>
                            <div className='leading-6'>
                                <span class="font-bold">Email:</span> <span className=''>powerbit.ar@gmail.com</span>
                            </div>
                        </div>


                        <div className=" flex justify-center pb-5">
                            <div>
                                <div className="text-center font-bold pt-6 pb-2 ">Seguinos en</div>


                                <div className="block mt-2">
                                    <div className="flex  gap-5  justify-center">
                                        <div className="">
                                            <a href="https://www.instagram.com/powerbit.ar/" target="_blank" >
                                                <svg xmlns="http://www.w3.org/2000/svg" className="ico-seguinos instagram" viewBox="0 0 448 512"><path d="M224.1 141c-63.6 0-114.9 51.3-114.9 114.9s51.3 114.9 114.9 114.9S339 319.5 339 255.9 287.7 141 224.1 141zm0 189.6c-41.1 0-74.7-33.5-74.7-74.7s33.5-74.7 74.7-74.7 74.7 33.5 74.7 74.7-33.6 74.7-74.7 74.7zm146.4-194.3c0 14.9-12 26.8-26.8 26.8-14.9 0-26.8-12-26.8-26.8s12-26.8 26.8-26.8 26.8 12 26.8 26.8zm76.1 27.2c-1.7-35.9-9.9-67.7-36.2-93.9-26.2-26.2-58-34.4-93.9-36.2-37-2.1-147.9-2.1-184.9 0-35.8 1.7-67.6 9.9-93.9 36.1s-34.4 58-36.2 93.9c-2.1 37-2.1 147.9 0 184.9 1.7 35.9 9.9 67.7 36.2 93.9s58 34.4 93.9 36.2c37 2.1 147.9 2.1 184.9 0 35.9-1.7 67.7-9.9 93.9-36.2 26.2-26.2 34.4-58 36.2-93.9 2.1-37 2.1-147.8 0-184.8zM398.8 388c-7.8 19.6-22.9 34.7-42.6 42.6-29.5 11.7-99.5 9-132.1 9s-102.7 2.6-132.1-9c-19.6-7.8-34.7-22.9-42.6-42.6-11.7-29.5-9-99.5-9-132.1s-2.6-102.7 9-132.1c7.8-19.6 22.9-34.7 42.6-42.6 29.5-11.7 99.5-9 132.1-9s102.7-2.6 132.1 9c19.6 7.8 34.7 22.9 42.6 42.6 11.7 29.5 9 99.5 9 132.1s2.7 102.7-9 132.1z" /></svg>
                                            </a>
                                        </div>
                                        <div className="">

                                            <a href="http://www.facebook.com/powerbit.oficial/" target="_blank" >
                                                <svg xmlns="http://www.w3.org/2000/svg" className="ico-seguinos facebook" viewBox="0 0 320 512"><path d="M80 299.3V512H196V299.3h86.5l18-97.8H196V166.9c0-51.7 20.3-71.5 72.7-71.5c16.3 0 29.4 .4 37 1.2V7.9C291.4 4 256.4 0 236.2 0C129.3 0 80 50.5 80 159.4v42.1H14v97.8H80z" /></svg>
                                            </a>
                                        </div>
                                        <div className="">

                                            <a href="https://wa.me/p/8140746209285050/5491125870303" target="_blank" >
                                                <svg xmlns="http://www.w3.org/2000/svg" className="ico-seguinos ico_whatsapp" viewBox="0 0 448 512"><path d="M380.9 97.1C339 55.1 283.2 32 223.9 32c-122.4 0-222 99.6-222 222 0 39.1 10.2 77.3 29.6 111L0 480l117.7-30.9c32.4 17.7 68.9 27 106.1 27h.1c122.3 0 224.1-99.6 224.1-222 0-59.3-25.2-115-67.1-157zm-157 341.6c-33.2 0-65.7-8.9-94-25.7l-6.7-4-69.8 18.3L72 359.2l-4.4-7c-18.5-29.4-28.2-63.3-28.2-98.2 0-101.7 82.8-184.5 184.6-184.5 49.3 0 95.6 19.2 130.4 54.1 34.8 34.9 56.2 81.2 56.1 130.5 0 101.8-84.9 184.6-186.6 184.6zm101.2-138.2c-5.5-2.8-32.8-16.2-37.9-18-5.1-1.9-8.8-2.8-12.5 2.8-3.7 5.6-14.3 18-17.6 21.8-3.2 3.7-6.5 4.2-12 1.4-32.6-16.3-54-29.1-75.5-66-5.7-9.8 5.7-9.1 16.3-30.3 1.8-3.7 .9-6.9-.5-9.7-1.4-2.8-12.5-30.1-17.1-41.2-4.5-10.8-9.1-9.3-12.5-9.5-3.2-.2-6.9-.2-10.6-.2-3.7 0-9.7 1.4-14.8 6.9-5.1 5.6-19.4 19-19.4 46.3 0 27.3 19.9 53.7 22.6 57.4 2.8 3.7 39.1 59.7 94.8 83.8 35.2 15.2 49 16.5 66.6 13.9 10.7-1.6 32.8-13.4 37.4-26.4 4.6-13 4.6-24.1 3.2-26.4-1.3-2.5-5-3.9-10.5-6.6z" /></svg>
                                            </a>
                                        </div>
                                        <div className="">
                                            <a href="https://www.youtube.com/@Power-Bit" target="_blank">
                                                <svg xmlns="http://www.w3.org/2000/svg" className="ico-seguinos youtube" viewBox="0 0 576 512"><path d="M549.7 124.1c-6.3-23.7-24.8-42.3-48.3-48.6C458.8 64 288 64 288 64S117.2 64 74.6 75.5c-23.5 6.3-42 24.9-48.3 48.6-11.4 42.9-11.4 132.3-11.4 132.3s0 89.4 11.4 132.3c6.3 23.7 24.8 41.5 48.3 47.8C117.2 448 288 448 288 448s170.8 0 213.4-11.5c23.5-6.3 42-24.2 48.3-47.8 11.4-42.9 11.4-132.3 11.4-132.3s0-89.4-11.4-132.3zm-317.5 213.5V175.2l142.7 81.2-142.7 81.2z" /></svg>
                                            </a>
                                        </div>
                                    </div>
                                </div>


                                <div className="block mt-4">
                                    <div className="flex  gap-5  justify-center">
                                        <div className="">

                                            <a href="https://twitter.com/Powerbit_ar" target="_blank">
                                                <svg xmlns="http://www.w3.org/2000/svg" className="ico-seguinos x" viewBox="0 0 512 512"><path d="M389.2 48h70.6L305.6 224.2 487 464H345L233.7 318.6 106.5 464H35.8L200.7 275.5 26.8 48H172.4L272.9 180.9 389.2 48zM364.4 421.8h39.1L151.1 88h-42L364.4 421.8z" /></svg>
                                            </a>
                                        </div>
                                        <div className="">
                                            <a href="https://www.tiktok.com/@power.bit" target="_blank">
                                                <svg xmlns="http://www.w3.org/2000/svg" className="ico-seguinos tiktok" viewBox="0 0 448 512"  ><path d="M448 209.9a210.1 210.1 0 0 1 -122.8-39.3V349.4A162.6 162.6 0 1 1 185 188.3V278.2a74.6 74.6 0 1 0 52.2 71.2V0l88 0a121.2 121.2 0 0 0 1.9 22.2h0A122.2 122.2 0 0 0 381 102.4a121.4 121.4 0 0 0 67 20.1z" /></svg>
                                            </a>
                                        </div>
                                        <div className="">

                                            <a href="https://www.linkedin.com/company/power-bit" target="_blank">
                                                <svg xmlns="http://www.w3.org/2000/svg" className="ico-seguinos linkedin" viewBox="0 0 448 512"><path d="M100.3 448H7.4V148.9h92.9zM53.8 108.1C24.1 108.1 0 83.5 0 53.8a53.8 53.8 0 0 1 107.6 0c0 29.7-24.1 54.3-53.8 54.3zM447.9 448h-92.7V302.4c0-34.7-.7-79.2-48.3-79.2-48.3 0-55.7 37.7-55.7 76.7V448h-92.8V148.9h89.1v40.8h1.3c12.4-23.5 42.7-48.3 87.9-48.3 94 0 111.3 61.9 111.3 142.3V448z" /></svg>
                                            </a>
                                        </div>
                                        <div className="">
                                            <a href="https://www.reddit.com/user/Power_Bit/" target="_blank">
                                                <svg xmlns="http://www.w3.org/2000/svg" className="ico-seguinos reddit" viewBox="0 0 512 512"><path d="M373 138.6c-25.2 0-46.3-17.5-51.9-41l0 0c-30.6 4.3-54.2 30.7-54.2 62.4l0 .2c47.4 1.8 90.6 15.1 124.9 36.3c12.6-9.7 28.4-15.5 45.5-15.5c41.3 0 74.7 33.4 74.7 74.7c0 29.8-17.4 55.5-42.7 67.5c-2.4 86.8-97 156.6-213.2 156.6S45.5 410.1 43 323.4C17.6 311.5 0 285.7 0 255.7c0-41.3 33.4-74.7 74.7-74.7c17.2 0 33 5.8 45.7 15.6c34-21.1 76.8-34.4 123.7-36.4l0-.3c0-44.3 33.7-80.9 76.8-85.5C325.8 50.2 347.2 32 373 32c29.4 0 53.3 23.9 53.3 53.3s-23.9 53.3-53.3 53.3zM157.5 255.3c-20.9 0-38.9 20.8-40.2 47.9s17.1 38.1 38 38.1s36.6-9.8 37.8-36.9s-14.7-49.1-35.7-49.1zM395 303.1c-1.2-27.1-19.2-47.9-40.2-47.9s-36.9 22-35.7 49.1c1.2 27.1 16.9 36.9 37.8 36.9s39.3-11 38-38.1zm-60.1 70.8c1.5-3.6-1-7.7-4.9-8.1c-23-2.3-47.9-3.6-73.8-3.6s-50.8 1.3-73.8 3.6c-3.9 .4-6.4 4.5-4.9 8.1c12.9 30.8 43.3 52.4 78.7 52.4s65.8-21.6 78.7-52.4z" /></svg>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>



                </div>



                <div>


                    <p className=' text-center pb-2 font-bold  font-bold text-base'>Envíanos un mensaje</p>










                    {mensajeEnviado == false
                        ?





                        <form id='' onSubmit={handleSubmit((data) => {
                            onSubmit(data);
                            setIsLoading(true);
                        })} noValidate>



                            <div class="grid grid-cols-1 lg:grid-cols-2 gap-4 mb-4">
                                <div>

                                    <label htmlFor="first-name" className="block text-sm dark:font-medium font-semibold leading-6">
                                        Nombre
                                    </label>
                                    <div className="mt-2">




                                        <input
                                            type="text"
                                            name="nombre"
                                            {...register('nombre', { required: "requerido" })}
                                            className={errors.nombre ? 'formularioError' : 'formulario1'}
                                            placeholder='Laura'
                                            role="presentation"
                                            autoComplete="off"
                                        />

                                    </div>
                                    <ErrorMessage errors={errors} name="nombre" render={({ message }) => <p className='merror hidden'>{message}</p>} />


                                </div>

                                <div>


                                    <label htmlFor="first-name" className="block text-sm dark:font-medium font-semibold leading-6">
                                        Apellido
                                    </label>

                                    <div className="mt-2">
                                        <input
                                            type="text"
                                            name="apellido"
                                            {...register('apellido', { required: "requerido" })}
                                            className={errors.apellido ? 'formularioError' : 'formulario1'}
                                            placeholder='López'
                                            role="presentation"
                                            autoComplete="off"
                                        />
                                    </div>
                                    <ErrorMessage errors={errors} name="apellido" render={({ message }) => <p className='merror hidden'>{message}</p>} />


                                </div>
                            </div>




                            <div class="grid grid-cols-1 lg:grid-cols-2 gap-4 mb-4">


                                <div>


                                    <label htmlFor="first-name" className="block text-sm dark:font-medium font-semibold leading-6">
                                        Teléfono
                                    </label>
                                    <div className="mt-2">
                                        <input
                                            type="text"
                                            name="telefono"
                                            {...register('telefono', { required: "requerido" })}
                                            className={errors.telefono ? 'formularioError' : 'formulario1'}
                                            placeholder='11 2222 3333'
                                            role="presentation"
                                            autoComplete="off"
                                        />
                                        <ErrorMessage errors={errors} name="telefono" render={({ message }) => <p className='merror hidden'>{message}</p>} />
                                    </div>


                                </div>


                                <div>



                                    <label htmlFor="first-name" className="block text-sm dark:font-medium font-semibold leading-6">
                                        Email
                                    </label>
                                    <div className="mt-2">
                                        <input
                                            type="email"
                                            name="email"
                                            {...register('email', { required: "requerido", pattern: /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/ })}
                                            className={errors.email ? 'formularioError' : 'formulario1'}
                                            placeholder='LauraLopez@gmail.com'
                                            role="presentation"
                                            autoComplete="off"
                                        />
                                        <ErrorMessage errors={errors} name="email" render={({ message }) => <p className='merror hidden'>{message}</p>} />

                                    </div>

                                </div>


                            </div>









                            <label htmlFor="first-name" className="block text-sm dark:font-medium font-semibold leading-6">
                                Mensaje
                            </label>
                            <div className="mt-2">
                                <textarea
                                    rows={3}
                                    name='mensaje'
                                    {...register('mensaje', { required: "requerido" })}
                                    className={errors.mensaje ? 'formularioError' : 'formulario1'}
                                    placeholder='Hola, quiero hacer una pregunta...'
                                    role="presentation"
                                    autoComplete="off"
                                />
                                <ErrorMessage errors={errors} name="mensaje" render={({ message }) => <p className='merror hidden'>{message}</p>} />
                            </div>

                            <div className='text-center pt-4 ml-20 mr-20 '>
                                <button className='boton1' disabled={isLoading} type='submit'>
                                    {isLoading ?
                                        <BotonEnviando texto="Enviando"></BotonEnviando>
                                        : 'Enviar'}
                                </button>

                            </div>



                        </form>







                        :





                        <div className='text-center'>

                            <div className='mt-5'>
                                <span className="fa-layers fa-4x">
                                    <FontAwesomeIcon icon={faCircle} color="green" transform="grow-5" />
                                    <FontAwesomeIcon icon={faCheck} inverse />
                                </span>
                            </div>


                            <div className='comprastes font-bold mt-5 '>¡Mensaje enviado!</div>

                            <div >En breve nos pondremos en contacto.</div>
                            <div className='mt-1'>

                                <button onClick={() => { getMensajeEnviado(false); scrollIdentificador('contacto'); }} className=' boton1' type='submit'>
                                    Volver
                                </button>
                                <button onClick={() => { confites(); }} className=' boton1 hidden' type='submit'>
                                    confites
                                </button>
                            </div>



                        </div>






                    }
                </div>
            </div>




        </section >
    );
};